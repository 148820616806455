var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{attrs:{"tile":"","elevation":"2"}},[_c('v-card-title',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.$t('orders'))+" ")]),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.customerOrdersList,"items-per-page":10,"sort-by":'orderDate',"sort-desc":"","footer-props":{
                        itemsPerPageOptions: [10,20,50, -1],
                        'items-per-page-text': _vm.$t('entranceList-nrOfItems')
                    }},scopedSlots:_vm._u([{key:"item.utcDateCreated",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("luxon")(item.utcDateCreated,{ input: "formatutc", output: "formatlocal" }))+" ")]}},{key:"item.totalPriceWithoutVatInMinorCurrency",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.globalConvertMinorCurrencyToLocal(item.totalPriceWithoutVatInMinorCurrency))+" ")])]}},{key:"item.totalPriceIncludingVatInMinorCurrency",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.globalConvertMinorCurrencyToLocal(item.totalPriceIncludingVatInMinorCurrency))+" ")])]}},{key:"item.id",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.getOrderPdf(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-pdf ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }